import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomModal from "./../../components/ReactModal";

import moment from "moment";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { propertyService } from "../../_services";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./../../layout/footer";
import constant from "../../_config/constant";
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PropertyRow = ({
  handleStatus,
  getBedCount,
  property,
  setRefreshKey,
}) => {
  const [suspense, setSuspense] = useState(false);
  const toggleSuspense = () => {
    setSuspense((e) => !e);
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const togglePropertyStatus = async (status) => {
    toggleSuspense();
    handleStatus(property.id, status, toggleSuspense);
  };
  const handleDraftDelete = async () => {
    try {
      setIsDeleting(true);
      await propertyService.deleteDraft({
        propertyId: property.id,
      });
      setRefreshKey((old) => old + 1);
    } catch (error) {
      console.log(error);
    }
    setIsDeleting(false);
  };

  return (
    <tr key={property.id}>
      <td>
        <div className="tw-flex tw-flex-col tw-space-y-1">
          {property.status != "Draft" && (
            <Link to={"/properties/edit/" + property.id}>
              <li className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md  tw-text-white">
                Edit
              </li>
            </Link>
          )}
          {property.status == "Draft" && (
            <>
              <span className="tw-block tw-p-2">
                <button
                  onClick={handleDraftDelete}
                  className=" tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white"
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </button>
              </span>

              <Link
                className="tw-block tw-p-2"
                to={"/properties/add/" + property.id}
              >
                <li className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white">
                  Update Draft
                </li>
              </Link>
            </>
          )}
          {property.status == "Listed" && (
            <li
              onClick={(e) => {
                togglePropertyStatus("Unlisted");
              }}
              className="tw-cursor-pointer tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white"
            >
              <a id={property.id} key={property.id}>
                {suspense ? "Please wait..." : "Unlist"}
              </a>
            </li>
          )}
          {property.status == "Unlisted" && (
            <li
              onClick={(e) => {
                togglePropertyStatus("Listed");
              }}
              className="tw-cursor-pointer tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white"
            >
              <a id={property.id} key={property.id}>
                {suspense ? "Please wait..." : "List"}
              </a>
            </li>
          )}
        </div>
      </td>
      <td>
        <span className="fs-16">{property.listingId} </span>
      </td>
      <td>
        <span className="fs-16">{property.listingName} </span>
      </td>

      <td>
        <span className="fs-16">{property.address} </span>
      </td>
      <td>
        <span className="fs-16">{property.rentalType} </span>
      </td>
      <td>
        <span className="fs-16">{property.accomodationCapacity} </span>
      </td>
      <td>
        <span className="fs-16">
          {getBedCount(property.sleepingArrangements)}{" "}
        </span>
      </td>
      <td>
        <span className="fs-16">{property.propertyType} </span>
      </td>
      <td>
        <span className="fs-16">{property.bookingType} </span>
      </td>

      <td>
        <span className="fs-16">{property.covidVerified} </span>
      </td>
      <td>
        <span className="fs-16">
          {property.reservedDays ? property.reservedDays : "--"}{" "}
        </span>
      </td>
      <td>
        <span className="fs-16">
          {property.lineholderDays ? property.lineholderDays : "--"}{" "}
        </span>
      </td>
      <td>
        <span className="fs-16">{property.status} </span>
      </td>
      <td>
        <span className="fs-16">
          {moment(property.createdAt).format("MMMM Do, YYYY")}
        </span>
      </td>
      <td>
        <span className="fs-16">
          {moment(property.updatedAt).format("MMMM Do, YYYY")}
        </span>
      </td>
      <td>
        <span className="fs-16">{property.rejectReason}</span>
      </td>
      <td>
        <span className="fs-16">-- </span>
      </td>
      <td>
        <span className="fs-16">-- </span>
      </td>
      <td>
        <span className="fs-16">-- </span>
      </td>
      <td>
        <span className="fs-16">-- </span>
      </td>
      <td>
        <span className="fs-16">-- </span>
      </td>
    </tr>
  );
};

export default function propertyList(props) {
  const [requesting, setRequesting] = useState(true);
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  var limit = 10;

  const available = []; // list.filter((d) => d.status === "Available");
  const booked = []; //list.filter((d) => d.status === "Booked");
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const getBedCount = (Arrangements) => {
    if (!Arrangements || !Arrangements.length) return 0;
    return Arrangements.reduce(function(acc, obj) {
      return acc + parseInt(obj.numberOfBeds);
    }, 0);
  };

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  useEffect(() => {
    propertyService.list({ page, limit: 10 }).then(
      (objS) => {
        setList(objS.data.docs);
        setTotal(objS.data.total);
        setRequesting(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setRequesting(false);
      }
    );
  }, [refreshKey]);

  const handleStatus = (id, status, cb) => {
    propertyService.updateStatus({ propertyId: id, status: status }).then(
      (objS) => {
        if (objS.status) {
          setRefreshKey((oldKey) => oldKey + 1);
          if (status == "Listed") {
            toast.success("Property Listed");
          } else if (status == "Unlisted") {
            toast.success("Property unlisted");
          }
        }
        cb();
      },
      (error) => {
        cb();
      }
    );
  };

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar
        user={decoded}
        showSearch={false}
        handleSearch={handleSearch}
        title="Property List"
        search={search}
      />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              {/* <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
                <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#All"
                        role="tab"
                      >
                        All Room ({available.length})
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Pending"
                        role="tab"
                      >
                        Available Room ({available.length})
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Booked"
                        role="tab"
                      >
                        Booked ({booked.length})
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Listing Id</th>
                          <th style={{ minWidth: "250px" }}>Listing Name</th>
                          <th style={{ minWidth: "300px" }}>Address</th>
                          <th>Rental Type</th>
                          <th>Accommodates</th>
                          <th>Beds Listed</th>
                          <th>Property Type</th>
                          <th>Booking Type</th>
                          <th>Covid Verified</th>
                          <th>Reserve Stay Limit</th>
                          <th>Lineholder Stay Limit</th>
                          <th>Status</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Reject Reason</th>
                          <th>View Count</th>
                          <th>Approved Count</th>
                          <th>Denied Count</th>
                          <th>Expired Count</th>
                          <th>Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((property) => (
                          <PropertyRow
                            setRefreshKey={setRefreshKey}
                            handleStatus={handleStatus}
                            property={property}
                            key={property.id}
                            getBedCount={getBedCount}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {requesting && (
              <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                <div className="bg-white tw-w-max rounded shadow-md p-4">
                  <div className="loader"></div>
                </div>
              </div>
            )}
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
