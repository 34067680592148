export function isValidEmail(email) {
  // Regular expression for a simple email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
}

export function isValidUSPhoneNumber(phoneNumber) {
  // Regular expression for a simple US phone number validation
  const phoneRegex = /^\d{10}$/;

  // Remove non-numeric characters from the phone number
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  return phoneRegex.test(numericPhoneNumber);
}
export function validateWebsiteAddress(website) {
  const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
  return urlPattern.test(website);
}

export function validateUSPostalCode(zip) {
  // Regular expression to match a valid US postal code
  const zipRegex = /^\d{5}(?:-\d{4})?$/;

  return zipRegex.test(zip);
}

export function validateSSNLast4(ssnLast4) {
  // Use a regular expression to match exactly 4 digits
  const ssnLast4Pattern = /^\d{4}$/;

  // Test if the provided input matches the pattern
  return ssnLast4Pattern.test(ssnLast4);
}

export function isAgeValid(day, month, year) {
  // Create a Date object with the current date
  const currentDate = new Date();

  // Extract the current year, month, and day
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: Month is zero-based, so we add 1
  const currentDay = currentDate.getDate();

  // Calculate the age based on the current date
  const age =
    currentYear -
    year -
    (currentMonth > month || (currentMonth === month && currentDay >= day)
      ? 0
      : 1);

  // Check if the age is between 13 and 120 (inclusive)
  return age >= 13 && age <= 120;
}
