import React, { useEffect, useState } from 'react'
import { BiUser } from 'react-icons/bi'
import { FiLogOut } from 'react-icons/fi'
import Tippy from '@tippy.js/react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
// import logoTitle from "../logo-title.png";
// import logoIcon from "../logo-icon.png";
import { useHistory } from 'react-router-dom'
import { logout, getData } from '../_helpers'
import constant from '../_config/constant'
import { processMediaUrl } from '../_helpers/utils'

function TopBar(props) {
    var host = getData()
    const history = useHistory()
    const handlelogout = () => {
        logout()
        history.push('/home')
    }

    var profile = host.avatar ? processMediaUrl(host.avatar) : '/images/user.png'

    return (
        <React.Fragment>
            <div className="hidden-navigation">
                <div className="nav-header">
                    <a className="brand-logo">
                        <img className="logo-abbr tw-h-10" src="/logo-icon.png" alt="" />
                        <img className="brand-title tw-mr-2" src="/logo-title.png" alt="" />
                    </a>
                    <div className="nav-control">
                        <div className="hamburger">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </div>
                    </div>
                </div>
                <div className="chatbox">
                    <div className="chatbox-close"></div>
                    <div className="custom-tab-1">
                        <div className="tab-content"></div>
                    </div>
                </div>

                <div className="header">
                    <div className="header-content">
                        <nav className="navbar navbar-expand">
                            <div className="collapse navbar-collapse justify-content-between">
                                <div className="header-left">
                                    <div className="dashboard_bar">{props.title}</div>
                                </div>
                                <ul className="navbar-nav header-right">
                                    {props.showSearch && (
                                        <li className="nav-item">
                                            <div className="input-group search-area">
                                                <form>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search here"
                                                        value={props.search}
                                                        onChange={e => props.handleSearch(e)}
                                                    />
                                                </form>
                                                <span className="input-group-text">
                                                    <a href="javascript:void(0)">
                                                        <i className="flaticon-381-search-2"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </li>
                                    )}

                                    <li className="nav-item dropdown header-profile">
                                        <Tippy
                                            interactive
                                            theme="light"
                                            className="tw-bg-white "
                                            arrow={false}
                                            trigger="mouseenter"
                                            content={
                                                <div className="tw-m-0 tw-bg-white tw-shadow-md tw-rounded-lg">
                                                    <Link to="/profile" className="dropdown-item ai-icon tw-flex tw-items-center">
                                                        <BiUser className="tw-text-primary tw-mr-2" size={24} />
                                                        <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">
                                                            Profile{' '}
                                                        </span>
                                                    </Link>

                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        className="dropdown-item ai-icon tw-flex tw-items-center"
                                                        onClick={handlelogout}
                                                    >
                                                        <FiLogOut className="tw-text-primary tw-mr-2" size={24} />
                                                        <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">
                                                            Logout{' '}
                                                        </span>
                                                    </a>
                                                </div>
                                            }
                                        >
                                            <a className="nav-link">
                                                <img src={profile} alt="" />
                                            </a>
                                        </Tippy>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="tw-flex tw-items-center mobile-nav tw-justify-between">
                <div className="tw-flex tw-items-center tw-gap-4">
                    <a>
                        <img className="logo-abbr md:tw-h-10 mobile-nav-logo" src="/logo-icon.png" alt="" />
                    </a>
                    <div className="nav-control mobile-nav-control">
                        <div className="tw-flex tw-flex-col  hamburger mobile-menu">
                            <span className="line mobile-line"></span>
                            <span className="line mobile-line"></span>
                            <span className="line mobile-line"></span>
                        </div>
                    </div>
                </div>
                <div>
                    <nav className="navbar navbar-expand">
                        <ul className="">
                            <li className="nav-item dropdown header-profile">
                                <Tippy
                                    interactive
                                    theme="light"
                                    className="tw-bg-white "
                                    arrow={false}
                                    trigger="mouseenter"
                                    content={
                                        <div className="tw-m-2 tw-bg-white tw-shadow-md tw-rounded-lg">
                                            <Link to="/profile" className="dropdown-item ai-icon tw-flex tw-items-center">
                                                <BiUser className="tw-text-primary tw-mr-2" size={24} />
                                                <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">Profile </span>
                                            </Link>

                                            <a
                                                style={{ cursor: 'pointer' }}
                                                className="dropdown-item ai-icon tw-flex tw-items-center"
                                                onClick={handlelogout}
                                            >
                                                <FiLogOut className="tw-text-primary tw-mr-2" size={24} />
                                                <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">Logout </span>
                                            </a>
                                        </div>
                                    }
                                >
                                    <a className="nav-link">
                                        <img src={profile} alt="temp" width={40} height={40} />
                                    </a>
                                </Tippy>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TopBar
